import axios from "axios";

const API_BASE_URL = "https://api.homeplans.io/v1";

const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})

export const register = (userData) => api.post('/user/register/', userData);

export const login = (credentials) => api.post('/user/login/', credentials);

export const generateImage = (description, token) =>
    api.post('/app/generate-image/', {description}, {headers: {Authorization: `Token ${token}`}});

export default api;