import React from "react";
import {Dialog, DialogContent, CircularProgress, Typography} from "@mui/material";

const LoadingDialog = ({open, message}) => {
    return (
        <Dialog open={open}>
            <DialogContent style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
                <CircularProgress/>
                <Typography style={{marginTop: '1rem'}}>{message}</Typography>
            </DialogContent>
        </Dialog>
    );
};

export default LoadingDialog;