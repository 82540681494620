import React from 'react';
import { Container, Typography, Button, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    logo: {
        width: '60%', // Adjust width as needed
        maxWidth: 500, // Maximum width
        margin: '0 auto', // Center the logo
        display: 'block',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(4),  // Increased margin for better spacing
    },
    image: {
        width: '60%',
        height: 'auto',
        marginTop: theme.spacing(4),  // Adjusted margin for better spacing
        objectFit: 'contain'
    },
    link: {
        textDecoration: 'none',
    },
}));

const Home = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <img src={`${process.env.PUBLIC_URL}/homepage_logo.png`} alt="Homepage Logo" className={classes.logo} />

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">
                            Describe your floor plan
                        </Typography>
                        <Typography>
                            Enter a detailed description of your desired floor plan, and our AI will generate a visual representation for you.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">
                            Generate your floor plan
                        </Typography>
                        <Typography>
                            Use our AI-powered tool to convert your text description into a detailed floor plan image.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <div style={{ marginTop: '2rem' }}> {/* Added margin here for better spacing */}
                <Link to="/generate-image" className={classes.link}>
                    <Button variant="contained" color="primary" size="large" className={classes.button}>
                        Get Started
                    </Button>
                </Link>
            </div>
            <Footer/>
            {/*<img src={`${process.env.PUBLIC_URL}/homeplans_example.png`} alt="Example Floor Plan" className={classes.image} />*/}
        </Container>
    );
};

export default Home;
