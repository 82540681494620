import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate, Link,} from "react-router-dom";
import {AppBar, Toolbar, Button} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Home from "./components/Home";
import Register from "./components/Register";
import Login from "./components/Login";
import GenerateImage from "./components/GenerateImage";
import PrivateRoute from "./components/PrivateRoute";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        textDecoration: 'none',
        color: 'inherit',
    },
    button: {
        margin: theme.spacing(1),
    },
    logo: {
        height: 50, // Adjust the size as needed
        marginRight: theme.spacing(2), // Optional margin
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));


const App = () => {
    const [token, setToken] = useState(localStorage.getItem('token') || '');
    const isAuthenticated = !!token;

    const classes = useStyles();

    const handleLogout = () => {
        localStorage.removeItem('token');
        setToken('');
        return <Navigate to={"/"} replace={true}/>;
    }

    return (
        <Router>
            <div>
                <AppBar position={"static"}>
                    <Toolbar className={classes.toolbar}>
                        <Link to={'/'} style={{textDecoration: 'none'}}>
                            <img
                                src={`${process.env.PUBLIC_URL}/navbar_logo.png`}
                                alt="Navbar Logo"
                                className={classes.logo}

                            />
                        </Link>
                        {isAuthenticated ? (
                            <Button
                                variant={"outlined"}
                                color={"inherit"}
                                className={classes.button}
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to="/login"
                                className={classes.button}
                            >
                                Login
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
                <Routes>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={"/register"} element={<Register/>}/>
                    <Route path="/login" element={<Login setToken={setToken}/>}/>
                    <Route
                        path={"/generate-image"}
                        element={
                            <PrivateRoute isAuthenticated={isAuthenticated}>
                                <GenerateImage token={token}/>
                            </PrivateRoute>
                        }
                    />
                    {/*<Route path="/" element={<Navigate to={isAuthenticated ? "/generate-image" : "/login"} replace/>}/>*/}
                </Routes>
            </div>
        </Router>
    );
};

export default App;