import React, {useState} from "react";
import {login} from "../services/api";
import {useNavigate} from 'react-router-dom';
import {TextField, Button, Container, Typography, Link} from "@mui/material";
import LoadingDialog from "./LoadingDialog";

const Login = ({setToken}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await login({email, password});
            setToken(response.data.token);
            localStorage.setItem('token', response.data.token);
            setMessage('Login successful!');
            navigate('/generate-image');
        } catch (error) {
            setMessage('Login failed.');
        }
        setLoading(false);
    };

    return (
        <Container maxWidth={"xs"} style={{marginTop: '2rem'}}>
            <Typography variant={"h4"} gutterBottom>
                Login
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label={"Email"}
                    variant={"outlined"}
                    fullWidth
                    margin={"normal"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label={"Password"}
                    variant={"outlined"}
                    type={"password"}
                    fullWidth
                    margin={"normal"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button variant={"contained"} color={"primary"} type={"submit"} fullWidth>
                    Login
                </Button>
                {message && (
                    <Typography variant="body2" color="error" style={{marginTop: '1rem'}}>
                        {message}
                    </Typography>
                )}
            </form>
            <LoadingDialog open={loading} message={"Logging in..."}/>
            <Typography variant="body2" style={{marginTop: '1rem'}}>
                Don't have an account?{' '}
                <Link href="/register" variant="body2">
                    Register
                </Link>
            </Typography>
        </Container>
    );
};

export default Login;