import React, {useEffect, useState} from "react";
import {register} from "../services/api";
import {Button, Container, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import LoadingDialog from "./LoadingDialog";

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // count down timer state
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        let intervalId;
        if (message === 'Registration successful! Plsease log in.') {
            intervalId = setInterval(() => {
                setCountdown((prevCount) => prevCount - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [message]);

    useEffect(() => {
        if (countdown === 0) {
            navigate('/login');
        }
    }, [countdown, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage("Passwords do not match");
            return;
        }

        setLoading(true);
        setMessage('');

        try {
            await register({email, password});
            setLoading(false);
            setMessage('Registration successful! Plsease log in.');
        } catch (error) {
            if (error.response && error.response.data) {
                setLoading(false);
                setMessage(error.response.data.email[0]);
            } else {
                setLoading(false);
                setMessage('Registration failed. Please try again.');
            }
        }
    };

    return (
        <Container maxWidth={"xs"} style={{marginTop: '2rem'}}>
            <Typography variant={"h4"} gutterBottom>
                Register
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label={"Email"}
                    variant={"outlined"}
                    fullWidth
                    margin={"normal"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label={"Password"}
                    variant={"outlined"}
                    type={"password"}
                    fullWidth
                    margin={"normal"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    label="Re-enter Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button variant={"contained"} color={"primary"} type={"submit"} fullWidth>
                    Submit
                </Button>
                {message && (
                    <Typography variant="body2" color="error" style={{marginTop: '1rem'}}>
                        {message}
                    </Typography>
                )}
            </form>
            <LoadingDialog open={loading} message={"Registering..."}/>
            {message.includes('successful') && (
                <Typography variant="body2" style={{marginTop: '1rem'}}>
                    Redirecting to login page in {countdown} second{countdown !== 1 ? 's' : ''}...
                </Typography>
            )}
        </Container>
    );
};

export default Register;

