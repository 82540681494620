import React, {useState} from "react";
import {generateImage} from "../services/api";
import { Container, Typography, Grid, TextField, Button, Paper, Box, CircularProgress, Divider } from "@mui/material";

const GenerateImage = ({token}) => {
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await generateImage(description, token);
            setImage(response.data.image);
            setMessage('Image generation seccesful!');
        } catch (error) {
            setMessage('Image generation failed.')
        } finally {
            setLoading(false); // Turn off loading animation
        }
    };

    return (
        <Container maxWidth={'lg'} style={{marginTop: '2rem'}}>
            <Typography variant={"h4"} gutterBottom>
                Generate Floor Plan
            </Typography>
            <Grid container spacing={2} alignItems={'stretch'}>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        style={{
                            padding: '1rem',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <form onSubmit={handleSubmit} style={{flexGrow: 1}}>
                            <TextField
                                label={"Enter the description of the floor plan"}
                                multiline
                                rows={10}
                                variant={"outlined"}
                                fullWidth
                                margin={"normal"}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <Box mt={2}>
                                <Button variant={"contained"} color={"primary"} type={"submit"} fullWidth
                                        disabled={loading}>
                                    {loading ? <CircularProgress size={24}/> : 'Generate Image'}
                                </Button>
                            </Box>
                        </form>
                        {message && (
                            <Typography variant={"body1"} color={"textSecondary"} style={{marginTop: '1rem'}}>
                                {message}
                            </Typography>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={3}
                        style={{
                            padding: '1rem',
                            textAlign: 'center',
                            minHeight: '300px',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: image ? 'none': 'repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 10px, #e0e0e0 10px, #e0e0e0 20px)',
                        }}
                    >
                        {image ? (
                            <img src={`data:image/png;base64,${image}`} alt={"Generated Floor Plan"}
                                 style={{maxWidth: '100%'}}/>
                        ) : (
                            <Typography variant={"body1"} color={"textSecondary"}>
                                No image generated yet.
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Box marginTop="2rem">
                <Paper
                    elevation={3}
                    style={{
                        padding: "1rem",
                        marginBottom: "2rem",
                    }}
                >
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            fontStyle: "italic",
                            textDecoration: "underline",
                            marginBottom: "1rem",
                        }}
                    >
                        Example
                    </Typography>
                    <Divider style={{ marginBottom: "1rem" }} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="body1"
                                paragraph
                                style={{
                                    fontFamily: "Arial",
                                    textAlign: "justify",
                                    // overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxHeight: "10rem",
                                    marginBottom: "1rem",
                                }}
                            >
                                <span style={{ textDecoration: "underline" }}>Text:</span> Balcony is the small part 15 feet length and 2 feet width, near room as master room. Bathroom is the small room it is entrance from living room 2 feet length and 2 feet width, ratio as 2 over 1. Common room 1 is the near room as bathroom and living room 10 feet length 8 feet width ratio as 5 over 4. Common room 2 is the entrance from living room 10 feet length and 8 feet width ratio as 5 over 4, near room as kitchen. Kitchen is the important room. Common room 1 and common room 2 between kitchen room. 5 feet length 2 feet width. Living room is the center of the room. it is the entrance room from west side 30 feet length, 20 feet width near rooms are common room 1, common room 2. Kitchen, master room and bath room ratio as 6 over 5...
                                {/*Master room is entrance from living room 10 feet length 10 feet width near room as bath room ratio as 3 over 2*/}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src={`${process.env.PUBLIC_URL}/homeplans_example.png`}
                                alt="Example Floor Plan"
                                style={{ maxWidth: "100%" }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
};

export default GenerateImage;