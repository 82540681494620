import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '300px',
                padding: '0.2rem',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '8px 0 0 0',
                boxShadow: '0 -1px 6px rgba(0,0,0,0.1)',
                textAlign: 'left',
            }}
        >
            <Typography variant="body2">
                Automatic Rendering of House Floor Plan Images Applying the Saudi Building Code
            </Typography>
            <Typography variant="body2">
                Capstone – Data Science: DS698
            </Typography>
            <Typography variant="body2">
                By: Abdullah Al Towiresh
            </Typography>
            <Typography variant="body2">
                Project Advisor
            </Typography>
            <Typography variant="body2">
                Dr Mohamed I. Habib
            </Typography>
            <Typography variant="body2">
                2023/2024
            </Typography>
        </Box>
    );
};

export default Footer;
